import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import './AccessRequestList.scss'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useClientRequests } from '../../hooks/useClientRequests'
import { saveAs } from 'file-saver-es'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { Button as DEButton, ButtonTypes } from 'devextreme-react/button'
import {
  DataGrid,
  Column,
  Export,
  Sorting,
  Selection,
  Scrolling,
  LoadPanel,
  HeaderFilter,
  SearchPanel,
  Item,
  Toolbar,
  FilterRow,
  DataGridTypes,
  Paging,
  ColumnChooser,
  Button,
  Summary,
  TotalItem,
  GroupPanel,
  Grouping,
  StateStoring,
  Pager,
  ColumnFixing,
} from 'devextreme-react/data-grid'
import { ClientAccessRequest, ICellData } from '../../models'
import { padLeadingZeroesCombined } from '../../formatters/padLeadingZeros'
import {
  convertDateToPresentationFormat,
  dateDifference,
} from '../../redux/helpers'
import { Loader } from '../Loader'
import { UserErrModal } from '../AddNewUser/UserErrModal'
import { useParams } from 'react-router'
import { GridButton } from './GridButton'
import useWindowDimensions from '../Shared/useWindowDimensions'
import {
  REACT_APP_WORKDAYWORKFLOWURL,
  REACT_APP_CEMURL,
  REACT_APP_SERVICENOWURL,
} from '../../envVariables'
const AccessRequestListScene = () => {
  const {
    getAllClientRequests,
    requestGetStatus,
    requestErrors,
    clientRequestResults,
    approveClientRequest,
    requestStatus,
    isClientAccessApprover,
    hasApproverAccess,
  } = useClientRequests()
  const dataGridRef = useRef<DataGrid>(null)
  const [isUserErrModalOpen, setIsUserErrModalOpen] = useState<boolean>(false)
  const { Id } = useParams<{ Id: string }>()
  const approvedCalled = requestStatus === 'Processing' // while  processing we want the spinner
  const processing = requestGetStatus === 'Success'
  const storageKey = 'TSA1CLIENTACCESS'
  const { height, width } = useWindowDimensions()
  const windowHeightOffset = 75
  const windowWidthOffset = 15

  // copy method to handle the export of the grid info to excell spreadsheet.
  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'DataGrid.xlsx'
        )
      })
    })
  }

  // verify the user has approver permissions.
  useEffect(() => {
    hasApproverAccess()
    // eslint-disable-next-line
  }, [])

  // get all  of clientRequests
  useEffect(() => {
    getAllClientRequests()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (requestStatus === 'Failed') {
      setIsUserErrModalOpen(true)
    }
  }, [requestStatus])

  // set up a filter on the  id column.
  // cannot get this to trigger on first launch of the screen,
  // useEffect(() => {
  //   // setTimeout (()=> {
  //     if (Id) {
  //       const dataGrid = dataGridRef?.current?.instance
  //       if (dataGrid) {
  //         dataGrid.filter(['id', '=', Id])
  //       }
  //     }
  //   // },0 )

  // }, [Id, processing])
  // close the error modal
  const handleUserErrModalClose = () => {
    setIsUserErrModalOpen(false)
  }
  ////////// Calculated values  methods used for  presentation  used for date, concate of fields ///////////////////
  const getRequesterFullname = (rowdata: ClientAccessRequest) => {
    const fullname = rowdata.firstName + ' ' + rowdata.lastName
    return fullname
  }
  const getClientandMasterId = (rowdata: ClientAccessRequest) => {
    const fullId = padLeadingZeroesCombined(rowdata.clientId, rowdata.masterId)
    return fullId
  }
  const getApprovedDate = (rowdata: ClientAccessRequest) => {
    const formattedDate = convertDateToPresentationFormat(rowdata.approvedDate)
    return formattedDate
  }
  const getCreatedDate = (rowdata: ClientAccessRequest) => {
    const formattedDate = convertDateToPresentationFormat(rowdata.createdDate)
    return formattedDate
  }
  const getApproverFullname = (rowdata: ClientAccessRequest) => {
    if (rowdata?.approverFirstName && rowdata?.approverLastName) {
      return rowdata?.approverFirstName + ' ' + rowdata?.approverLastName
    } else {
      return ''
    }
  }
  const getNotificationDate = (rowdata: ClientAccessRequest) => {
    const formattedDate = convertDateToPresentationFormat(
      rowdata.notificationDate
    )
    return formattedDate
  }
  const getRequestToApporovalTime = (rowdata: ClientAccessRequest) => {
    const formattedDiff = dateDifference(
      'hours',
      rowdata.createdDate,
      rowdata.approvedDate
    )
    return formattedDiff
  }
  const approveRequest = (gridcell: ICellData<ClientAccessRequest>) => {
    const requestId = gridcell.row?.data?.id
    if (requestId) {
      approveClientRequest(requestId)
    }
  }

  const getErrorMessages = (rowdata: ClientAccessRequest) => {
    let formattedErrorMessage = ''
    if (rowdata.approvedErrMessage) {
      const errorMessages = rowdata.approvedErrMessage.split('|')

      errorMessages.forEach((error: string) => {
        formattedErrorMessage += error + '<br/>'
      })
    }

    return formattedErrorMessage
  }

  const isApproveRequestVisible = (
    gridcell: ICellData<ClientAccessRequest>
  ) => {
    if (gridcell.row) {
      if (gridcell.row.data)
        return gridcell.row.data.requestStatus !== 'APPROVED'
      else {
        return false
      }
    } else {
      return false
    }
  }
  const renderActionButton = (gridcell: ICellData<ClientAccessRequest>) => {
    if (gridcell.row) {
      if (gridcell.row.data)
        return <GridButton buttonCaption={'APPROVE REQUEST'} />
      else {
        return null
      }
    } else {
      return null
    }
  }
  const renderSNOWIncidentButton = (
    gridcell: ICellData<ClientAccessRequest>
  ) => {
    if (gridcell.row) {
      if (gridcell.row.data?.seviceNowIncidentNumber)
        return (
          <GridButton
            buttonCaption={gridcell.row.data.seviceNowIncidentNumber}
          />
        )
      else {
        return null
      }
    } else {
      return null
    }
  }

  const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
    10,
    50,
    'all',
  ]

  // attempt to set filter progamically.
  const onClickTestFilter = () => {
    if (Id) {
      const dataGrid = dataGridRef?.current?.instance
      if (dataGrid) {
        dataGrid.filter(['id', '=', Id])
      }
    }
  }
  // ClearFilters
  const onClickClearFilter = () => {
    const dataGrid = dataGridRef?.current?.instance
    if (dataGrid) {
      dataGrid.clearFilter()
    }
  }
  const onclickSNOWIncident = (gridcell: ICellData<ClientAccessRequest>) => {
    if (gridcell.row?.data?.serviceNowIncidentSysId) {
      const serviceNowUrl = `${REACT_APP_SERVICENOWURL}/nav_to.do?uri=incident.do?sys_id=${gridcell.row?.data?.serviceNowIncidentSysId}`
      window.open(serviceNowUrl, '_blank', 'noreferrer')
    }
  }

  const refreshDataGrid = useCallback(() => {
    dataGridRef?.current?.instance.state(null)
  }, [])
  const LaunchCEM = useCallback(() => {
    window.open(REACT_APP_CEMURL, '_blank', 'noreferrer')
  }, [])

  const LaunchWorkday = useCallback(() => {
    window.open(REACT_APP_WORKDAYWORKFLOWURL, '_blank', 'noreferrer')
  }, [])

  // not authorized return null  the not authorized page was quickly coming up so we do not want that.
  if (!isClientAccessApprover) {
    return null
  }

  ///////////////////////////////////// Custom Load and Save State//////////////////////////////////////
  const customLoadState = () => {
    const gridstate = window.localStorage.getItem(storageKey)
    if (gridstate) {
      // we have a passed in filter we need to apply and clear other filters.
      if (Id) {
        const checkgrid = JSON.parse(gridstate)
        if (checkgrid) {
          // we want to clear out filter and set the filter of id Column to the ID passed in.
          for (let i = 0; i < checkgrid.columns.length; i++) {
            // Here WE SET THE ID  or clear the idS.
            if (checkgrid.columns[i].name === 'id') {
              checkgrid.columns[i].filterValues = [Number(Id)]
            } else {
              checkgrid.columns[i].filterValues = null
            }
          }
        }
        return checkgrid
      }
      // just return parsed grid if no Id
      else {
        return JSON.parse(gridstate)
      }
    }
  }
  // eslint-disable-next-line
  const customSaveState = (gridState: any) => {
    window.localStorage.setItem(storageKey, JSON.stringify(gridState))
  }

  // Sort for columns  that need special sort alogoritms/ code
  // this is fo the Requst approve Differnte field.
  // exmaple is 1.32 hours  we dor a float parse on hte sting and the float gets converted.
  // so in the example here 1.32 is the value the normal string compare does not proper compare two values.
  const sortRequestToApporovalTime = (value1?: any, value2?: any): number => {
    if (!value1 && value2) return -1
    if (!value1 && !value2) return 0
    if (value1 && !value2) return 1
    const float1 = parseFloat(value1)
    const float2 = parseFloat(value2)
    if (float1 > float2) return -1
    if (float1 < float2) return 1
    if (float1 === float2) return 0
    return 0
  }

  return (
    // we want  margin around the grid
    <div
      style={{
        margin: 10,
      }}
    >
      {/* // this spinner is while approve reqest processing. */}
      <Loader ready={!approvedCalled}>
        <div></div>
      </Loader>
      {/* // this is for the entire screen loading */}
      <Loader ready={processing}>
        <DataGrid
          id='gridContainer'
          ref={dataGridRef}
          showBorders={true}
          dataSource={clientRequestResults}
          onExporting={onExporting}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          keyExpr={'id'}
          height={height - windowHeightOffset}
          width={width - windowWidthOffset}
        >
          {/*  allow multiple column sorting with  */}
          <Sorting mode='multiple' />
          <Toolbar>
            {/* <Item location="before">
          <div className="informer">
            <div className="count">{totalCount}</div>
            <span>Total Count</span>
          </div>
        </Item>
        <Item location="before">
          <SelectBox
            width="225"
            items={groupingValues}
            displayExpr="text"
            inputAttr={countLabel}
            valueExpr="value"
            value={groupColumn}
            onValueChanged={toggleGroupColumn} />
        </Item>
        <Item location="before">
          <Button
            text={expandAll ? 'Collapse All' : 'Expand All'}
            width='136'
            onClick={toggleExpandAll} />
        </Item>
        <Item location="after">
          <Button
            icon='refresh'
            onClick={refreshDataGrid} />
        </Item> */}
            <Item location='before' visible={!!Id ? true : false}>
              <DEButton
                icon='filter'
                text='Set Filter'
                hint={
                  'This will set filter to request with Id (' +
                  Id +
                  ')  from url.'
                }
                onClick={onClickTestFilter}
              />
            </Item>
            <Item location='before' visible={true}>
              <DEButton
                icon='clear'
                text='Clear Filters'
                hint='This  will clear all column filters.'
                onClick={onClickClearFilter}
              />
            </Item>
            <Item location='before' visible={true}>
              <DEButton
                icon='refresh'
                text='Reset'
                hint='This will reset the presented  columns to the base columns and clear filters.'
                onClick={refreshDataGrid}
              />
            </Item>
            <Item location='after' visible={true}>
              <DEButton
                icon='link'
                text='Workday'
                hint='This launches Workday add me screen.'
                onClick={LaunchWorkday}
              />
            </Item>
            <Item location='after' visible={true}>
              <DEButton
                icon='link'
                text='CEM'
                hint='This launches CEM.'
                onClick={LaunchCEM}
              />
            </Item>
            <Item location='before' name='columnChooserButton' />
            <Item location='before' name='searchPanel' />
            <Item location='before' name='exportButton' />
          </Toolbar>
          {/* <Scrolling columnRenderingMode='virtual' /> */}
          {/* // Group on clientId */}
          {/* <GroupPanel visible={true} />
        <Grouping autoExpandAll={true} /> */}
          {/* <FilterRow visible={true} /> */}
          <ColumnChooser enabled={true} mode={'dragAndDrop'} />
          <ColumnFixing enabled={false} />{' '}
          {/* enable for some reason fixes the action column to the right?. */}
          <Selection
            mode='multiple'
            selectAllMode={'allPages'}
            showCheckBoxesMode={'always'}
          />
          {/* <Paging defaultPageSize={40} /> */}
          <StateStoring
            enabled={true}
            type='custom'
            storageKey={storageKey}
            customLoad={customLoadState}
            customSave={customSaveState}
          />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={false}
            showNavigationButtons={false}
          />
          <HeaderFilter visible={true} />
          {/* this is for the header filter on each column */}
          <SearchPanel visible={true} />
          <Column
            caption='ID'
            allowSearch={true}
            allowSorting={true}
            dataField='id'
            visible={true}
          ></Column>
          <Column
            caption='NAME'
            allowSearch={true}
            allowSorting={true}
            dataField='calculatedName' // just need this so filter show up.
            calculateCellValue={getRequesterFullname}
          ></Column>
          <Column
            caption='EMAIL'
            allowSearch={true}
            allowSorting={true}
            dataField='email'
          ></Column>
          <Column
            caption='CLIENT ID'
            //groupIndex={0}
            allowSearch={true}
            allowSorting={true}
            calculateCellValue={getClientandMasterId}
            dataField='clientId' // will show the filter if has a datafield set
          ></Column>
          <Column
            type='buttons'
            caption='ACTION'
            allowSearch={true}
            allowSorting={true}
            dataField='action'
          >
            <Button
              text='APPROVE REQUEST'
              hint='APPROVE REQUEST'
              onClick={approveRequest}
              visible={isApproveRequestVisible}
              render={renderActionButton}
            />
          </Column>
          <Column
            caption='STATUS'
            allowSearch={true}
            allowSorting={true}
            dataField='requestStatus'
          ></Column>
          <Column
            caption='TSA'
            allowSearch={true}
            allowSorting={true}
            dataField='tsaAuthorized'
            visible={true}
          ></Column>
          <Column
            caption='TWB'
            allowSearch={true}
            allowSorting={true}
            dataField='twbAuthorized'
            visible={true}
          ></Column>
          <Column
            caption='DMS'
            allowSearch={true}
            allowSorting={true}
            dataField='dmsAuthorized'
            visible={true}
          ></Column>
          <Column
            caption='STATUS ERROR MSG'
            allowSearch={true}
            allowSorting={true}
            dataField='approvedErrMessage'
            calculateCellValue={getErrorMessages}
            encodeHtml={false}
            width={'20%'}
          ></Column>
          <Column
            caption='DATE APPROVED'
            allowSearch={true}
            allowSorting={true}
            dataField='approvedDate' // if column then filter will be enabled.
            calculateCellValue={getApprovedDate}
          ></Column>
          <Column
            caption='NOTIFICATION SENT'
            allowSearch={true}
            allowSorting={true}
            dataField='notificationDate'
            calculateCellValue={getNotificationDate}
          ></Column>
          <Column
            caption='NOTIFICATION STATUS'
            allowSearch={true}
            allowSorting={true}
            dataField='notificationStatus'
            visible={false}
          ></Column>
          <Column
            caption='NOTIFICATION ERROR MSG'
            allowSearch={true}
            allowSorting={true}
            dataField='notificationErrMessage'
            visible={false}
          ></Column>
          <Column
            caption='APPROVER USER ID'
            allowSearch={true}
            allowSorting={true}
            dataField='approverUserId'
            visible={false}
          ></Column>
          <Column
            caption='APPROVER NAME'
            allowSearch={true}
            allowSorting={true}
            dataField='calcfield' // just need this so filter show up.
            visible={false}
            calculateCellValue={getApproverFullname}
          ></Column>
          <Column
            caption='APPROVER FIRST NAME'
            allowSearch={true}
            allowSorting={true}
            dataField='approverFirstName'
            visible={false}
          ></Column>
          <Column
            caption='APPROVER LAST NAME'
            allowSearch={true}
            allowSorting={true}
            dataField='approverLastName'
            visible={false}
          ></Column>
          <Column
            caption='REQUESTED DATE'
            allowSearch={true}
            allowSorting={true}
            dataField='createdDate'
            visible={false}
            calculateCellValue={getCreatedDate}
          ></Column>
          <Column
            caption='REQUESTED TO APPROVED'
            allowSearch={true}
            allowSorting={true}
            dataField='placeholderTime'
            visible={false}
            calculateCellValue={getRequestToApporovalTime}
            sortingMethod={sortRequestToApporovalTime}
          ></Column>
          <Column
            caption='REQUESTED USER ID'
            allowSearch={true}
            allowSorting={true}
            dataField='userId'
            visible={false}
          ></Column>
          <Column
            caption='REQUESTER FIRST NAME'
            allowSearch={true}
            allowSorting={true}
            dataField='firstName'
            visible={false}
          ></Column>
          <Column
            caption='REQUESTER LAST NAME'
            allowSearch={true}
            allowSorting={true}
            dataField='lastName'
            visible={false}
          ></Column>
          <Column
            type='buttons'
            caption='SNOW INCIDENT'
            allowSearch={true}
            allowSorting={true}
            visible={true}
            dataField='seviceNowIncidentNumber'
          >
            <Button
              onClick={onclickSNOWIncident}
              render={renderSNOWIncidentButton}
            />
          </Column>
          <Export enabled={true} allowExportSelectedData={true} />
          <Summary>
            <TotalItem column='id' summaryType='count' />
          </Summary>
        </DataGrid>
      </Loader>
      {isUserErrModalOpen && (
        <UserErrModal
          handleModalClose={handleUserErrModalClose}
          userErrs={requestErrors}
          titleErr={'Errors in Approve Request:'}
        />
      )}
    </div>
  )
}

export default AccessRequestListScene
