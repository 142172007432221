import {
  Engagement,
  TeamAssignment,
} from '../../models'

export type FormModel = Partial<Engagement> & {
  [key: string]: any
  id?: number
  name: string
  taxYear: string
  clientId: string
  engagementTaxForm: string
  fundType: string
  priorYearReturnId: string
  cchVersion: number
  irsDateField: string
  setupDateField?: string
  pbcDateField?: string
  reviewDateField?: string
  cchDateField?: string
  totalExpectedUnits: number
  rsmTeamAssignments: TeamAssignment[]
  clientTeamAssignments: TeamAssignment[]
}

export interface SelectFieldOption {
  label: string
  value: string
}

/** Contains information about EngagementPhaseTypes related to
 *  how the application works with them.
 */
export interface EngagementPhaseInfoRecord {
  /** The display value for the related value. */
  label: string

  /** The value associated with the label. */
  value: string

  /**
   * Boolean value indicating whether or not an engagement can be transitioned to this
   *   phase, manually from the user.
   */
  canTransitionTo: boolean

  /** Boolean value indicating whether or not the user can manually transition from this phase type. */
  canTransitionFrom: boolean

  /** Boolean value indicating whether or not the user should be able to see the state in the form.
   *   Special value of 'disabled' indicates the user can see the state, but not change it.
   */
  showState: boolean | 'disabled'
}

export const ENGAGEMENT_PHASE_INFO: EngagementPhaseInfoRecord[] = [
  {
    value: 'setup',
    label: 'RSM Setup',
    canTransitionTo: false,
    canTransitionFrom: false,
    showState: false,
  },
  {
    value: 'clientVal',
    label: 'Client Setup',
    canTransitionTo: true,
    canTransitionFrom: true,
    showState: true,
  },
  {
    value: 'pbc',
    label: 'PBC',
    canTransitionTo: true,
    canTransitionFrom: true,
    showState: true,
  },
  {
    value: 'review',
    label: 'RSM Review',
    canTransitionTo: true,
    canTransitionFrom: true,
    showState: true,
  },
  {
    value: 'cch',
    label: 'CCH',
    canTransitionTo: true,
    canTransitionFrom: true,
    showState: true,
  },
  {
    value: 'irs',
    label: 'Filed with IRS',
    canTransitionTo: true,
    canTransitionFrom: true,
    showState: true,
  },
  {
    value: 'closed',
    label: 'Closed',
    canTransitionTo: true,
    canTransitionFrom: false,
    showState: false,
  },
]

// HACK FOR DEBUG - ONLY NEEDED WHEN WORKING WITH IT.
// ENGAGEMENT_PHASE_INFO.forEach(v => {
//   v.canTransitionFrom = true
//   v.canTransitionTo = true
//   v.showState = true
// })
