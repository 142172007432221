import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { saveAs } from 'file-saver-es'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { Button as DEButton } from 'devextreme-react/button'
import {
  DataGrid,
  Column,
  Export,
  Sorting,
  Selection,
  HeaderFilter,
  SearchPanel,
  Item,
  Toolbar,
  DataGridTypes,
  ColumnChooser,
  Button,
  Summary,
  TotalItem,
  StateStoring,
  Pager,
  ColumnFixing,
} from 'devextreme-react/data-grid'
import { ICellData } from '../../models'
import { padLeadingZeroesCombined } from '../../formatters/padLeadingZeros'
import { convertDateToPresentationFormat } from '../../redux/helpers'
import { Loader } from '../Loader'
import { useHistory } from 'react-router'
import useWindowDimensions from '../Shared/useWindowDimensions'
import { REACT_APP_TSAAPPURL } from '../../envVariables'
import { Engagement, Index } from '../../models'
import { AppDispatch, AppState, thunks } from '../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, Modal, Theme, Button as RButton } from '@rsmus/react-ui'
import { FaExclamationCircle, FaHeart } from 'react-icons/fa'
import { createUseStyles } from 'react-jss'
import { ensureNumber } from '@rsmus/guards'

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    loading: {
      fontSize: '1.2rem',
      margin: '15px 0',
      textAlign: 'center',
    },
    noEngagements: {
      fontSize: '1.2rem',
      marginTop: 40,
      padding: '10px 0 10px 20px',
    },
    engagementListScreen: {
      position: 'absolute',
      width: '100%',
    },
    engagementListTable: {
      width: '100%',
      '& tr td': {
        padding: 10,
        verticalAlign: 'top',
      },
      '& tr td:first-child': {
        paddingLeft: 20,
      },
      '& tr td:last-child': {
        paddingRight: 20,
      },
      '& tr:nth-child(even) td': {
        backgroundColor: palette.grey[100],
      },
      '& tr:hover td': {
        backgroundColor: palette.grey[200],
      },
    },
    engagementListTableHeader: {
      backgroundColor: palette.grey[300],
      '& tr th': {
        padding: 10,
        verticalAlign: 'top',
      },
      '& tr th:first-child': {
        paddingLeft: 20,
      },
      '& tr th.text-center': {
        textAlign: 'center',
      },
    },
    button: {
      zIndex: 1,
      padding: '0.5rem',
      minWidth: '8rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
      textAlign: 'center',
    },
    '&:focus': {
      // TODO: Set a nicer style for focus outline
      outline: 'none',
    },
    buttonPrimary: {
      color: palette.common.white,
      backgroundColor: palette.blue.main,
      '&:disabled': {
        opacity: 0.4,
      },
    },
    modalText: {
      fontSize: '1.25rem',
    },
    modalButton: {
      margin: '2rem',
      marginBottom: '.1rem',
      width: '6rem',
      display: 'inline-flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    resultIcon: {
      fontSize: '4rem',
      marginBottom: '12px',
      color: palette.informational.yellow,
    },
    modalTitle: {
      fontSize: '1.8rem',
      lineHeight: '2rem',
      letterSpacing: '0.075rem',
      marginBottom: '1rem',
      maxWidth: '100%', // fix IE11 flexbox issue that allowed div to overflow the parent div
    },
    engagementButton: {
      display: 'inline-block',
      textAlign: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      width: 44,
      padding: 10,
      borderRadius: '50%',
      color: palette.blue.dark,
      '&:active, &:focus': {
        outline: 'none',
      },
      '&:hover': {
        color: palette.blue.main,
        backgroundColor: palette.common.white,
      },
      '&:last-child': {
        marginRight: '0',
      },
      '&:disabled': {
        color: palette.grey[400],
        cursor: 'not-allowed',
      },
      '&> .heartEmpty': {
        fill: 'none',
        strokeWidth: '1em',
        stroke: palette.blue.dark,
      },
      '&> .heartFilled': {
        fill: palette.blue.dark,
      },
      '&:hover > .heartEmpty, &:hover > .heartFilled': {
        fill: palette.blue.main,
      },
    }
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)
const EngagementListSceneNew = () => {
  const classes = useStyles()
  const dataGridRef = useRef<DataGrid>(null)
  const storageKey = 'TSAENGEMENTLIST'
  const { height, width } = useWindowDimensions()
  const windowHeightOffset = 75
  const windowWidthOffset = 15
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const engagements: Index<Engagement> = useSelector(
    (state: AppState) => state.engagements.data
  )
  const isLoading: Boolean = useSelector(
    (state: AppState) => state.engagements.isLoading
  )
  const [deleteModalShown, setDeleteModalShown] = useState(false)
  const [deleteEngagementId, setDeleteEngagementId] = useState<string | number>(
    ''
  )
  const gridEngagements = Object.values(engagements)
  useEffect(() => {
    dispatch(thunks.engagement.getAllEngagements())
  }, [dispatch])

  const toggleFavoriteEngagement = (gridcell: ICellData<Engagement>) => {
    dispatch(
      thunks.engagement.toggleFavorite(ensureNumber(gridcell.row?.data?.id))
    )
  }

  const showSoftDeleteModal = (gridcell: ICellData<Engagement>) => {
    setDeleteModalShown(true)
    setDeleteEngagementId(ensureNumber(gridcell.row?.data?.id))
  }

  const closeSoftDeleteModal = () => {
    setDeleteModalShown(false)
    setDeleteEngagementId('')
  }

  const softDeleteEngagement = () => {
    dispatch(thunks.engagement.softDeleteEngagement(deleteEngagementId))
    closeSoftDeleteModal()
  }
  // copy method to handle the export of the grid info to excell spreadsheet.
  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'DataGrid.xlsx'
        )
      })
    })
  }

  ////////// Calculated values  methods used for  presentation  used for date, concate of fields ///////////////////

  const getClientandMasterId = (rowdata: Engagement) => {
    const fullId = padLeadingZeroesCombined(
      rowdata.clientId,
      rowdata.client.masterId
    )
    return fullId
  }
  const getCreateddDate = (rowdata: Engagement) => {
    const formattedDate = convertDateToPresentationFormat(rowdata.creationDate)
    return formattedDate
  }
  const getCompletionDate = (rowdata: Engagement) => {
    const formattedDate = convertDateToPresentationFormat(
      rowdata.completionDate
    )
    return formattedDate
  }
  const getRollForwardDate = (rowdata: Engagement) => {
    const formattedDate = convertDateToPresentationFormat(
      rowdata.rollForwardDate
    )
    return formattedDate
  }
  const getLastUpdatedDate = (rowdata: Engagement) => {
    const formattedDate = convertDateToPresentationFormat(
      rowdata.lastUpdatedDate
    )
    return formattedDate
  }

  const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
    10,
    50,
    'all',
  ]

  // ClearFilters
  const onClickClearFilter = () => {
    const dataGrid = dataGridRef?.current?.instance
    if (dataGrid) {
      dataGrid.clearFilter()
    }
  }

  const refreshDataGrid = useCallback(() => {
    dataGridRef?.current?.instance.state(null)
  }, [])

  ///////////////////////////////////// Custom Load and Save State//////////////////////////////////////
  const customLoadState = () => {
    const gridstate = window.localStorage.getItem(storageKey)
    if (gridstate) {
      // we have a passed in filter we need to apply and clear other filters.
      return JSON.parse(gridstate)
    }
  }
  // eslint-disable-next-line
  const customSaveState = (gridState: any) => {
    window.localStorage.setItem(storageKey, JSON.stringify(gridState))
  }
  const tsaEngagementBaseUrl = REACT_APP_TSAAPPURL
    ? `${REACT_APP_TSAAPPURL}/engagements/`
    : ''
  const renderEngagmentLaunch = (
    data: DataGridTypes.ColumnCellTemplateData
  ) => {
    const id = data.row?.data?.id
    return (
      <a
        href={`${tsaEngagementBaseUrl}${id}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {data.text}
      </a>
    )
  }

  const handleEditClick = (gridcell: ICellData<Engagement>) => {
    history.push(`/engagements/${gridcell.row?.data?.id}`)
  }

  const renderFavoriteColumn = (row: any) => {
    if(row.data.favorited !== undefined) {
      return (
        <button
            type='button'
            className={classes.engagementButton}
            title='Toggle Favorite'
            onClick={() => toggleFavoriteEngagement(row)}
        >
            <FaHeart
            className={
              row.data.favorited ? 'heartFilled' : 'heartEmpty'
            }
            />
        </button>
      )
    }
  }

  return (
    // we want  margin around the grid
    <div
      style={{
        margin: 10,
      }}
    >
      {/* // this is for the entire screen loading */}
      <Loader ready={!isLoading}>
        <DataGrid
          id='gridContainer'
          ref={dataGridRef}
          showBorders={true}
          dataSource={gridEngagements}
          onExporting={onExporting}
          rowAlternationEnabled={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          keyExpr={'id'}
          height={height - windowHeightOffset}
          width={width - windowWidthOffset}
        >
          {/*  allow multiple column sorting with  */}
          <Sorting mode='multiple' />
          <Toolbar>
            <Item location='before' visible={true}>
              <DEButton
                icon='clear'
                text='Clear Filters'
                hint='This  will clear all column filters.'
                onClick={onClickClearFilter}
              />
            </Item>
            <Item location='before' visible={true}>
              <DEButton
                icon='refresh'
                text='Reset'
                hint='This will reset the presented  columns to the base columns and clear filters.'
                onClick={refreshDataGrid}
              />
            </Item>

            <Item location='before' name='columnChooserButton' />
            <Item location='before' name='searchPanel' />
            <Item location='before' name='exportButton' />
          </Toolbar>
          <ColumnChooser enabled={true} mode={'dragAndDrop'} sortOrder='asc' />
          <ColumnFixing enabled={false} />{' '}
          {/* enable for some reason fixes the action column to the right?. */}
          <Selection
            mode='multiple'
            selectAllMode={'allPages'}
            showCheckBoxesMode={'always'}
          />
          {/* <Paging defaultPageSize={40} /> */}
          <StateStoring
            enabled={true}
            type='custom'
            storageKey={storageKey}
            customLoad={customLoadState}
            customSave={customSaveState}
          />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={false}
            showNavigationButtons={false}
          />
          <HeaderFilter visible={true} />
          {/* this is for the header filter on each column */}
          <SearchPanel visible={true} />
          <Column
            caption='ID'
            allowSearch={true}
            allowSorting={true}
            dataField='id'
            visible={false}
          ></Column>
          <Column
            caption='CLIENT NAME'
            allowSearch={true}
            allowSorting={true}
            dataField='client.name'
            visible={true}
          ></Column>
          <Column
            caption='ENGAGEMENT NAME'
            allowSearch={true}
            allowSorting={true}
            dataField='name'
            cellRender={renderEngagmentLaunch}
            visible={true}
          ></Column>
          <Column
            caption='TAX FORM TYPE'
            allowSearch={true}
            allowSorting={true}
            dataField='engagementTaxForm'
            visible={true}
          ></Column>
          <Column
            caption='TEMPLATE ID'
            allowSearch={true}
            allowSorting={true}
            dataField='template.id'
            visible={false}
          ></Column>
          <Column
            caption='TAX YEAR'
            allowSearch={true}
            allowSorting={true}
            dataField='taxYear'
            visible={true}
          ></Column>
          <Column
            caption='CCH VERSION'
            allowSearch={true}
            allowSorting={true}
            dataField='cchVersion'
            visible={false}
          ></Column>
          <Column
            caption='CLIENT ID'
            //groupIndex={0}
            allowSearch={true}
            allowSorting={true}
            calculateCellValue={getClientandMasterId}
            dataField='clientId' // will show the filter if has a datafield set
          ></Column>
          <Column
            caption='7216'
            allowSearch={true}
            allowSorting={true}
            dataField='is7216Eligible'
            visible={true}
          ></Column>
          <Column
            caption='PHASE'
            allowSearch={true}
            allowSorting={true}
            dataField='phase'
            visible={true}
          ></Column>
          <Column
            caption='PRIOR YEAR RETURN ID'
            allowSearch={true}
            allowSorting={true}
            dataField='priorYearReturnId'
            visible={false}
          ></Column>
          <Column
            caption='ROLL FORWARD DATE'
            allowSearch={true}
            allowSorting={true}
            dataField='rollForwardDate'
            visible={true}
            calculateCellValue={getRollForwardDate}
          ></Column>
          <Column
            caption='LAST UPDATED DATE'
            allowSearch={true}
            allowSorting={true}
            dataField='lastUpdatedDate'
            calculateCellValue={getLastUpdatedDate}
            visible={true}
          ></Column>
          <Column
            name='favorite'
            type='buttons'
            caption='FAVORITE'
            allowSearch={false}
            allowSorting={false}
            cellRender={renderFavoriteColumn}
          />            
          <Column
            name='edit'
            type='buttons'
            caption='EDIT'
            allowSearch={false}
            allowSorting={false}
          >
            <Button
              hint='EditEngagment'
              icon='edit'
              visible={true}
              onClick={handleEditClick}
            />
          </Column>
          <Column
            name='delete'
            type='buttons'
            caption='DELETE'
            allowSearch={false}
            allowSorting={false}
          >
            <Button
              hint='Delete Engagement'
              icon='trash'
              visible={true}
              onClick={showSoftDeleteModal}
              disabled={(e) => e.row.data.phase !== 'setup'}
            />
          </Column>
          <Column
            caption='MASTER ID'
            allowSearch={true}
            allowSorting={true}
            dataField='client.masterId'
            visible={false}
          ></Column>
          <Export enabled={true} allowExportSelectedData={true} />
          <Summary>
            <TotalItem column='id' summaryType='count' />
          </Summary>
        </DataGrid>
      </Loader>
      <Modal shown={deleteModalShown} onClose={closeSoftDeleteModal}>
        <div>
          <FaExclamationCircle className={classes.resultIcon} />
          <div className={classes.modalTitle}>Delete Engagement</div>
          <div className={classes.modalText}>
            All engagement data and history will be deleted. This cannot be
            undone once completed. Would you still like to proceed?
          </div>
          <RButton
            className={classes.modalButton}
            onClick={closeSoftDeleteModal}
          >
            CANCEL
          </RButton>
          <RButton
            className={`${classes.modalButton} ${classes.buttonPrimary}`}
            onClick={softDeleteEngagement}
          >
            YES
          </RButton>
        </div>
      </Modal>
    </div>
  )
}

export default EngagementListSceneNew
