import { useRef, useCallback, useState } from 'react'
import {
  UserProfileModel,
  UserSearchRequest,
  ProvisionUserRequestModel,
  ProvisionUserResults,
} from '../models'
import ky from '@rsmus/ky-auth'
import { buildApiUrl } from '../apiUrl'
import { useDispatch } from 'react-redux'
import { AppDispatch, thunks } from '../redux'
import { Dictionary } from 'lodash'

export const useUsers = () => {
  const [requestStatus, setRequestStatus] = useState<string>('Idle')
  const [requestGetStatus, setRequestGetStatus] = useState<string>('Idle')
  const [findResults, setFindResults] = useState<UserProfileModel[]>([])
  const [requestErrors, setRequestErrors] = useState<Dictionary<string>>({})
  const [userResult, setUserResults] = useState<UserProfileModel>()
  const controller = useRef(new AbortController())
  const dispatch = useDispatch<AppDispatch>()
  // Search Users
  const findUser = useCallback(
    async (request: UserSearchRequest) => {
      const { clientId, parentOu, filter } = request

      const queryString = {
        clientId: clientId + '', // force a string
        filter: encodeURIComponent(filter + ''), // encode for '+' sign in the email address.
        parentOu: parentOu + '',
      }
      const url = buildApiUrl('users/search', queryString)
      try {
        setRequestGetStatus('Processing')
        const results = await ky
          .get(url, {
            timeout: 300000,
          })
          .json<UserProfileModel[]>()
        setFindResults(results)
        setRequestGetStatus('Success')
      } catch (error) {
        setRequestGetStatus('Failed')
      }
    },
    // eslint-disable-next-line
    []
  )
  
  const provisionUser = useCallback(
    async (request: ProvisionUserRequestModel) => {
      controller.current.abort()
      controller.current = new AbortController()
      setRequestErrors({})
      setRequestStatus('Processing')
      try {
        const { signal } = controller.current
        const url = buildApiUrl('users/provisionuser')
        const response = await ky.post(url, {
          json: request,
          signal,
          throwHttpErrors: false,
        })

        if (response.ok) {
          const results: ProvisionUserResults = await response.json()
          if (results.succeded === 1) {
            // refresh store with the new list of users.
            setTimeout(() => {
              dispatch(thunks.clientUsers.getById(request.organizationId))
              setRequestStatus('Success')
            }, 5000)
          } else {
            // we need to present an error message here.
            setRequestErrors(results.errors)
            setRequestStatus('Failed')
          }
        } else {
          const errorMessage = await response.text()
          setRequestErrors({ err: errorMessage })
          setRequestStatus('Failed')
        }
      } catch (error) {
        setRequestErrors({ err: 'Network is currently slow, please try again later' })
        setRequestStatus('Failed')
      }
    },
    // eslint-disable-next-line
    []
  )
  const getUserByEmail = useCallback(
    async (email: string) => {
      const queryString = {
        email: encodeURIComponent(email),
      }
      try {
        setRequestGetStatus('Processing')
        const { signal } = controller.current
        const url = buildApiUrl('users/getbyemail', queryString)
        const response = await ky.get(url, {
          signal,
          throwHttpErrors: false,
          timeout: 30000,
        })

        if (response.ok) {
          setRequestGetStatus('Success')
          try {
            // the respone may not have any info to convert so we catch ans set to
            if (response.status === 200) {
              // not found will return a 204.
              const results: UserProfileModel = await response.json()
              setUserResults(results)
            }
            setUserResults(undefined)
          } catch (error) {
            setUserResults(undefined)
          }
        } else {
          setUserResults(undefined)
        }
      } catch (error) {
        setRequestGetStatus('Failed')
        setUserResults(undefined)
      }
    },
    // eslint-disable-next-line
    []
  )
  const reactivateExternalUser = useCallback(
    async (rsmUid: string) => {
      const queryString = {
        rsmUid: encodeURIComponent(rsmUid),
      }
      try {
        setRequestGetStatus('Processing')
        const { signal } = controller.current
        const url = buildApiUrl('users/reactivateexternaluser', queryString)
        const response = await ky.get(url, {
          signal,
          throwHttpErrors: false,
          timeout: 30000,
        })

        if (response.ok) {
          setRequestGetStatus('Success')
          try {
            // the respone may not have any info to convert so we catch ans set to
            if (response.status === 200) {
              // not found will return a 204.
              const results: UserProfileModel = await response.json()
              setUserResults(results)
            } else{
            setUserResults(undefined)
            }
            
          } catch (error) {
            setUserResults(undefined)
          }
        } else {
            const errorMessage = await response.text()
          setRequestErrors({ err: errorMessage })
             setRequestGetStatus('Failed')
          setUserResults(undefined)
        }
      } catch (error) {
        setRequestGetStatus('Failed')
        setUserResults(undefined)
      }
    },
    // eslint-disable-next-line
    []
  )
  const resetPasswordExternalUser = useCallback(
    async (rsmUid: string) => {
      const queryString = {
        rsmUid: encodeURIComponent(rsmUid),
      }
      try {
        setRequestGetStatus('Processing')
        const { signal } = controller.current
        const url = buildApiUrl('users/resetpassword', queryString)
        const response = await ky.get(url, {
          signal,
          throwHttpErrors: false,
          timeout: 30000,
        })

        if (response.ok) {
          setRequestGetStatus('Success')
          try {
            // the respone may not have any info to convert so we catch ans set to
            if (response.status === 200) {
              // not found will return a 204.
              const results: UserProfileModel = await response.json()
              setUserResults(results)
            } else{
            setUserResults(undefined)
            }
            
          } catch (error) {
            setUserResults(undefined)
          }
        } else {
          const errorMessage = await response.text()
          setRequestErrors({ err: errorMessage })
          setRequestGetStatus('Failed')
          setUserResults(undefined)
        }
      } catch (error) {
        setRequestGetStatus('Failed')
        setUserResults(undefined)
      }
    },
    // eslint-disable-next-line
    []
  )
  const unlockExternalUser = useCallback(
    async (rsmUid: string) => {
      const queryString = {
        rsmUid: encodeURIComponent(rsmUid),
      }
      try {
        setRequestGetStatus('Processing')
        const { signal } = controller.current
        const url = buildApiUrl('users/unlock', queryString)
        const response = await ky.get(url, {
          signal,
          throwHttpErrors: false,
          timeout: 30000,
        })

        if (response.ok) {
          setRequestGetStatus('Success')
          try {
            // the respone may not have any info to convert so we catch ans set to
            if (response.status === 200) {
              // not found will return a 204.
              const results: UserProfileModel = await response.json()
              setUserResults(results)
            } else{
            setUserResults(undefined)
            }
            
          } catch (error) {
            setUserResults(undefined)
          }
        } else {
          const errorMessage = await response.text()
          setRequestErrors({ err: errorMessage })
          setRequestGetStatus('Failed')
          setUserResults(undefined)
        }
      } catch (error) {
        setRequestGetStatus('Failed')
        setUserResults(undefined)
      }
    },
    // eslint-disable-next-line
    []
  )
  return {
    requestStatus,
    findResults,
    requestErrors,
    userResult,
    requestGetStatus,
    setFindResults,
    findUser,
    provisionUser,
    getUserByEmail,
    reactivateExternalUser,
    resetPasswordExternalUser,
    unlockExternalUser,
  }
}
