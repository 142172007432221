import { env as env2 } from '@rsmus/react-env-image'

/*
    The following are values found in either the .env files or in the environment variables on the machine.
    Environment variables from the machine are preferred, but .env files can be useful in development.  In such
    cases, use .env.development, since that will not propagate to the production environment.
*/

// This is a temporary work around to allow tests to succeed.  The current env function will throw
//  errors if the window object doesn't have a config property.  The offending NPM package should be
//  updated soon, and this if-block can be removed at that time.
let env = env2
if ((window as any) != null && (window as any).config == null) {
  env = function () {
    return undefined
  }
}

export const REACT_APP_IDMAUTHENTICATIONCLIENTID = env(
  'REACT_APP_IDMAUTHENTICATIONCLIENTID'
)

export const REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS = env(
  'REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS'
)

export const REACT_APP_IDMAUTHENTICATIONAUTHORITY = env(
  'REACT_APP_IDMAUTHENTICATIONAUTHORITY'
)

export const REACT_APP_IDMAUTHENTICATIONEULASERVICEURL = env(
  'REACT_APP_IDMAUTHENTICATIONEULASERVICEURL'
)

export const REACT_APP_LOGINREDIRECTURL = env(
  'REACT_APP_LOGINREDIRECTURL'
)

export const REACT_APP_TSAAPPURL = env(
  'REACT_APP_TSAAPPURL'
)

export const REACT_APP_TSAAPIBASEURL = env(
  'REACT_APP_TSAAPIBASEURL'
)

export const REACT_APP_ENGAGEMENTMANAGERURL = env(
  'REACT_APP_ENGAGEMENTMANAGERURL'
)

export const REACT_APP_AUTHORIZATIONSERVICEURL = env(
  'REACT_APP_AUTHORIZATIONSERVICEURL'
)

export const REACT_APP_CEMURL = env(
  'REACT_APP_CEMURL'
)
export const REACT_APP_WORKDAYWORKFLOWURL = env(
  'REACT_APP_WORKDAYWORKFLOWURL'
)
export const REACT_APP_DEVEXTREMELICENSE = env(
  'REACT_APP_DEVEXTREMELICENSE'
)
export const REACT_APP_SERVICENOWURL  = env(
  'REACT_APP_SERVICENOWURL'
)