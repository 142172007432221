import * as yup from 'yup'

// Add method to handle RSM Team Assignment validation
yup.addMethod(yup.array, 'requiresRole', function (message, role) {
  return this.test('requiresRole', message, function (list = []) {
    return list.some(({ roles }: any) => {
      return roles && roles[role]
    })
  })
})

const optionalDateSchema = yup
  .date()
  .nullable()
  .typeError('Please select a valid date')

const optionalOwnershipValueSchema = yup
  .number()
  .min(0, 'Ownership percentages must be positive values')
  .test(
    'len',
    'Ownership percentages must be less than or equal to 11 characters',
    val => (val ? val.toString().length <= 11 : true)
  )
  .transform(value => (isNaN(value) ? undefined : value))

const getValidationSchema = (values: any) => {
  // Fields are in order of form appearance.
  // Errors are listed out in this order.
  return yup.object().shape({
    name: yup
      .string()
      .max(100, 'Name exceeded character limit: 100')
      .nullable(),
    engagementTaxForm: yup.string().required('A tax form must be selected'),
    taxYear: yup.string().required('An engagement tax year must be selected'),
    fundType: yup.string().nullable(),
    priorYearReturnId: yup
      .string()
      .matches(/^\d{4}(X|P):\d{7}-\d{7}:V[1-9]{1}$/gi, {
        message: 'Invalid Prior Year Return Id format',
        excludeEmptyString: true,
      }),
    clientId: yup
      .string()
      .max(7, 'Client ID must be seven digits or less')
      .required('Client ID is required'),
    cchVersion: yup.number().required('A CCH version must be selected'),
    irsDateField: yup
      .date()
      .required('Enter the File Return Due Date')
      .typeError('Please select a valid date'),
    pbcDateField: optionalDateSchema,
    reviewDateField: optionalDateSchema,
    setupDateField: optionalDateSchema,
    cchDateField: optionalDateSchema,
    rsmTeamAssignments: yup
      .array()
      .requiresRole(
        'At least one Primary Reviewer must be selected. Please choose a Primary reviewer for this engagement',
        'PrimaryReviewer'
      )
      .compact(function (v: any) {
        return !v.isAssigned
      })
      .min(1)
      .required('At least one employee must be assigned credentials')
  })
}

const getErrorsFromValidationError = (validationError: any) => {
  const FIRST_ERROR = 0
  return (
    validationError.inner &&
    validationError.inner.reduce((errors: any, error: any) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      }
    }, {})
  )
}

export const validate = (values: any) => {
  const validationSchema = getValidationSchema(values)
  try {
    validationSchema.validateSync(values, { abortEarly: false })
    return {}
  } catch (error) {
    return getErrorsFromValidationError(error)
  }
}
